import * as yup from "yup";

export const createGameSchema = yup
  .object({
    Description: yup.string().required("Game name is required"),
    template: yup.string(),
    GroupID: yup.string(),
    GameDetail: yup.string().required("Game details is required"),
    BannerFile: yup.string(),
    viewBannerFile:yup.string(),
    viewLeaderFile:yup.string(),
    startDate: yup.date().required("Start date is required"),
    endDate: yup
      .date()
      .required("End date is required")
      .min(yup.ref("startDate"), "End date can't be before start date"),
    startTime: yup.string().required("Start Time is required"),
    endTime: yup.string().required("End Time is required"),
    MinPlayers: yup
      .number()
      .required("Min players is required")
      .typeError("Min players is required")
      .positive("Min players must be positive"),
    MaxPlayers: yup
      .number()
      .required("Max players is required")
      .typeError("Max players is required")
      .integer("Max players must be positive")
      .when("MinPlayers", (minPyr, schema) => {
        return schema.test({
          name: "is-greater",
          message: "must be greater than min players",
          test: (maxPyr) => maxPyr >= minPyr,
        });
      }),
    LeaderboardBanner: yup.string(),
    VideoURL: yup.string(),
    RegistrationFee: yup
      .number()
      .required("Registration fee is required")
      .typeError("Registration fee is required")
      .integer("Registration fee must be positive")
      .when("gameType", (type, schema) => {
        return type == "paid"
          ? schema
              .required("Payout is required")
              .positive("Registration fee must be positive")
          : schema.notRequired();
      }),
    TotalPrizeMoney: yup
      .number()
      .required("Total prize is required")
      .typeError("Total prize is required")
      .integer("Total prize must be positive")
      .when("gameType", (type, schema) => {
        return type == "cash"
          ? schema
              .required("Total prize is required")
              .positive("Total prize must be positive")
          : schema.notRequired();
      }),
    PayoutType: yup.string().when("gameType", (type, schema) => {
      return type == "cash" || type == "paid"
        ? schema.required("Payout is required")
        : schema.notRequired();
    }),
    PlanID: yup.string().when("gameType", (type, schema) => {
      return type == "reward"
        ? schema.required("Plan name is required")
        : schema.notRequired();
    }),
    gameType: yup.string().required("Game type is required"),
    Latitude: yup.string(),
    Longitude: yup.string(),
    IsSaveasTemplate: yup.string(),
    isFeatured: yup.string(),
    Status: yup.string(),
    NumRegistered: yup.string(),
    IsUpdateTemplate: yup.string(),
    FeaturedPriority :yup.string()
  })
  .required();
