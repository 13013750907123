import {
  Box,
  MenuItem,
  Button,
  IconButton,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../../../styles/gamedetail.css";
import img from "../../../../assets/svg/image-picker-icon.png";
import deleteIcon from "../../../../assets/svg/delete-icon.svg";
import playIcon from "../../../../assets/svg/play.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { RHFSelect } from "../../../../components/RHF/RHFSelect";
import RHFTextField from "../../../../components/RHF/RHFTextfield";
import RHFDatepicker from "../../../../components/RHF/RHFDatepickers";
import { RHFCheckbox } from "../../../../components/RHF/RHFCheckbox";
import { useGroupIds } from "../../../../queries/groupId";
import RHFTimePicker from "../../../../components/RHF/RHFTimepicker";
import { useDefaultTemplate } from "../../../../queries/DefaultTemplates";
import dayjs from "dayjs";
import { deleteTemplate } from "../../../../services/game/template/apiDeleteTemplate";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { createGame } from "../../../../services/game/createGame";
import planeIndIcon from "./../../../../styles/images/planId_icon.svg";
import ViewPlanPopup from "../viewPlanId";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Spinner from "../../../../components/Spinner";
import VideoComponent from "../../../../components/VideoPopup/VideoPopup";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { createGameSchema } from "../../../../schemas/game/createGame";
import RHFTextEditor from "../../../../components/RHF/RHFTexteditor";
import RHFRadioGroup from "../../../../components/RHF/RHFRadiogroup";
import { useActivePlans } from "../../../../queries/activePlans";
export default function CreateGame() {
  const { groupIds } = useGroupIds();
  const { templates } = useDefaultTemplate();
  const queryClient = useQueryClient();
  const [viewPlan, setViewPlan] = useState(false);
  const [viewVideo, setViewVideo] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [templateId, setTemplateId] = useState(false);
  const [templateEvent, setTemplateEvent] = useState(false);
  const bannerInputRef = useRef(null);
  const leaderboardBannerRef = useRef(null);
  const { activePlans } = useActivePlans();

  const navigate = useNavigate(null);
  const methods = useForm({
    resolver: yupResolver(createGameSchema),
    defaultValues: {
      Description: "",
      template: "",
      GroupID: "",
      GameDetail: "",
      MinPlayers: "",
      MaxPlayers: "",
      VideoURL: "",
      RegistrationFee: "",
      TotalPrizeMoney: "0",
      PayoutType: "",
      PlanID: "",
      Latitude: "",
      Longitude: "",
      IsSaveasTemplate: false,
      LeaderboardBanner: "",
      BannerFile: "",
      gameType: "paid",
      isFeatured: false,
      viewLeaderFile: "",
      viewBannerFile: "",
      FeaturedPriority: "",
      IsUpdateTemplate: false,
    },
  });
  useEffect(() => {
    const gameType = methods.watch("gameType");

    if (gameType === "cash") {
      methods.setValue("PlanID", "");
    }
    if (gameType != "paid") {
      methods.setValue("RegistrationFee", "0");
    }

    if (gameType != "cash") {
      methods.setValue("TotalPrizeMoney", "0");
    }
    if (gameType == "cash" || gameType == "paid") {
      methods.setValue("PlanID", "");
    }
    if (gameType === "reward") {
      methods.setValue("PayoutType", "");
    }
  }, [methods.watch("gameType")]);

  const handlePopup = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    setOpen(false);
    templateEvent.stopPropagation();
    deleteTemplate(templateId)
      .then((res) => {
        console.log("delete templates response == ", res);
        toast.success(res.data);
        queryClient.invalidateQueries({
          queryKey: ["defaultTemplate"],
        });
      })
      .catch((err) => {
        toast.error("Error in deleting template");
        console.log("delete templateerror === ", err);
      });
  };

  const handleImageUpload = (event, key, viewFile) => {
    const file = event.target.files[0];

    if (file) {
      methods.setValue(key, file);
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("result of image == ", reader.result);
        methods.setValue(viewFile, reader.result);
        console.log("image file === ", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onSubmit = () => {
    // Retrieve date and time values
    const startDate = methods.getValues("startDate");
    const endDate = methods.getValues("endDate");
    const startTime = methods.getValues("startTime");
    const endTime = methods.getValues("endTime");

    // Create formatted date-time strings
    const StartDateTime = dayjs(
      `${dayjs(startDate).format("YYYY-MM-DD")} ${dayjs(startTime).format(
        "HH:mm:ss.SSS"
      )}`
    ).format("YYYY-MM-DD HH:mm:ss.SSS");

    const EndDateTime = dayjs(
      `${dayjs(endDate).format("YYYY-MM-DD")} ${dayjs(endTime).format(
        "HH:mm:ss.SSS"
      )}`
    ).format("YYYY-MM-DD HH:mm:ss.SSS");

    // Collect form values into an object
    const formData = {
      Description: methods.getValues("Description"),
      GroupID: methods.getValues("GroupID"),
      GameDetail: methods.getValues("GameDetail"),
      MinPlayers: parseInt(methods.getValues("MinPlayers")) || 0,
      MaxPlayers: parseInt(methods.getValues("MaxPlayers")) || 0,
      VideoURL: methods.getValues("VideoURL"),
      RegistrationFee: parseFloat(methods.getValues("RegistrationFee")) || 0,
      TotalPrizeMoney: methods.getValues("TotalPrizeMoney") || "0",
      PayoutType: methods.getValues("PayoutType"),
      PlanID: methods.getValues("PlanID"),
      Latitude: methods.getValues("Latitude")
        ? parseFloat(methods.getValues("Latitude"))
        : "",
      Longitude: methods.getValues("Longitude")
        ? parseFloat(methods.getValues("Longitude"))
        : "",
      LeaderboardBanner: methods.getValues("LeaderboardBanner"),
      BannerFile: methods.getValues("BannerFile"),
      StartDateTime,
      EndDateTime,
      IsSaveasTemplate: methods.getValues("IsSaveasTemplate"),
      IsUpdateTemplate: methods.getValues("IsUpdateTemplate"),
      TemplateId:
        methods.getValues("IsUpdateTemplate") == true
          ? parseInt(methods.getValues("template"))
          : null,
      FeaturedPriority: parseInt(methods.getValues("FeaturedPriority")) || "",
    };

    if (typeof methods.getValues("BannerFile") == "object") {
      formData.BannerFile = methods.getValues("BannerFile");
    } else {
      formData.BannerFileURL = methods.getValues("BannerFile");
      formData.BannerFile = "";
    }
    if (typeof methods.getValues("LeaderboardBanner") == "object") {
      formData.LeaderboardBanner = methods.getValues("LeaderboardBanner");
    } else {
      formData.LeaderboardBannerURL = methods.getValues("LeaderboardBanner");
      formData.LeaderboardBanner = "";
    }
    console.log("form data with datetime === ", formData);

    setLoading(true);
    createGame(formData)
      .then((res) => {
        toast.success("Game Created Successfully.");
        navigate("/games");
        console.log("create game response === ", res);
        queryClient.invalidateQueries({
          queryKey: ["getGames"]
        })
      })
      .catch((err) => {
        const status = err.response ? err.response.status : 500;
        setLoading(false);
        if (status === 400) {
          toast.error("Bad Request. Please check your input and try again.");
        } else if (status === 401) {
          toast.error("Unauthorized. Please login again.");
        } else if (status === 500) {
          toast.error("Server Error. Please try again later.");
        } else {
          toast.error(`Error: ${err.response?.statusText || "Something went wrong."}`);
        }
        console.log("create game error == ", err);
      })
      .finally(() => {
        setLoading(false); // Ensure loading state is reset
      });
  };

  function getGameType(game) {
    let fee = parseFloat(game?.RegistrationFee || 0);
    let prize = parseFloat(game?.TotalPrizeMoney || 0);

    let payout = game?.PayoutType?.length > 0;
    let plan = game?.PlanId?.length > 0 || game?.PlanID?.length > 0;

    if (fee !== 0 && payout) {
      return "paid";
    } else if (prize !== 0 && payout && fee == 0) {
      return "cash";
    } else if (plan) {
      return "reward";
    }
    return ''
  }

  console.log("errors == ", methods.formState.errors);

  const handleTemplates = (id) => {
    const selectedTemplate = templates.filter((ele) => ele.ID == id)[0];

    methods.setValue("Description", selectedTemplate.GameName);
    methods.setValue("GroupID", selectedTemplate.GroupID);
    methods.setValue("GameDetail", selectedTemplate.GameDetail);
    methods.setValue(
      "startTime",
      dayjs(selectedTemplate.StartTime, "HH:mm:ss.SSS")
    );
    methods.setValue(
      "endTime",
      dayjs(selectedTemplate.EndTime, "HH:mm:ss.SSS")
    );
    methods.setValue("MinPlayers", parseInt(selectedTemplate.MinPlayers));
    methods.setValue("MaxPlayers", parseInt(selectedTemplate.MaxPlayers));
    methods.setValue("VideoURL", selectedTemplate.VideoUrl);
    methods.setValue(
      "RegistrationFee",
      parseFloat(selectedTemplate.RegistrationFee)
    );
    methods.setValue(
      "TotalPrizeMoney",
      parseFloat(selectedTemplate.TotalPrizeMoney)
    );
    methods.setValue("PayoutType", selectedTemplate.PayoutType);
    methods.setValue("LeaderboardBanner", selectedTemplate.LeaderboardBanner);
    methods.setValue("BannerFile", selectedTemplate.BannerFile);
    methods.setValue("viewBannerFile", selectedTemplate.BannerFile);
    methods.setValue("viewLeaderFile", selectedTemplate.BannerFile);
    methods.setValue("gameType", getGameType(selectedTemplate));
    methods.setValue("PlanID", selectedTemplate.PlanID);
    console.log(getGameType(selectedTemplate), "getGameType(selectedTemplate)");
  };
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Typography
        className="page-title"
        fontFamily={"Lato"}
        fontSize={28}
        fontWeight={700}
      >
        Create Game
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box className="game-detail-container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <RHFSelect
                  name={"template"}
                  label="Choose a Template"
                  renderValue={(selected) => {
                    if (!selected) {
                      return "Choose a template"; // Default display when no template is selected
                    }
                  }}
                  native={false}
                  className="select-template"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "50px",
                      color: "white",
                      backgroundColor: "rgba(40, 126, 173, 1)",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "white",
                      fontSize: "15px", // Font size for input conten
                    },
                    padding: "0px",
                    borderRadius: "4px",
                    border: "1px solid rgba(40, 126, 173, 0.12)",
                  }}
                >
                  <MenuItem key={1234} value="" onClick={() => methods.reset()}>
                    <em>+ Create New</em>
                  </MenuItem>
                  {templates?.map((template) => (
                    <MenuItem
                      key={template.ID}
                      sx={{ fontWeight: 400, color: "primary" }}
                      value={template.ID}
                      onClick={() => handleTemplates(template.ID)}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <span style={{ width: "300px" }}>
                          {template.GameName}
                        </span>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setTemplateEvent(e);
                            setTemplateId(template.ID);
                            handlePopup();
                          }}
                          sx={{
                            padding: "0",
                            marginLeft: "16px",
                            visibility:
                              methods.watch("template") == template.ID
                                ? "hidden"
                                : "visible",
                          }}
                        >
                          <img src={deleteIcon} />
                        </IconButton>
                      </Box>
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} md={5}>
                <RHFTextField
                  name="Description"
                  InputProps={{
                    style: {
                      color: "gray",
                    },
                  }}
                  className="required-field"
                  fullWidth
                  label="Game Name"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFSelect
                  name="GroupID"
                  label="Group ID"
                  style={{
                    color: "rgba(81, 92, 99, 1)",
                    fontSize: "14px",
                  }}
                >
                  {groupIds.length > 0 ? (
                    groupIds.map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          sx={{
                            fontSize: "14px",
                            color: "rgba(81, 92, 99, 1)",
                          }}
                          value={item.GroupID}
                        >
                          {item.GroupID}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>empty</MenuItem>
                  )}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} md={8} height={106}>
                <RHFTextEditor
                  name="GameDetail"
                  placeholder="Enter game details"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="image-section">
                  <div className="image-upload">
                    <label className="floating-label">Banner Image</label>
                    <div
                      onClick={() => {
                        triggerFileInput(bannerInputRef);
                      }}
                    >
                      {methods.watch("BannerFile") ? (
                        <img
                          src={methods.getValues("viewBannerFile")}
                          alt="Banner"
                          className="uploaded-img"
                        />
                      ) : (
                        <div className="upload-placeholder">
                          <img
                            src={img}
                            alt="Upload Icon"
                            className="upload-icon"
                          />
                          <Typography
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 0.6)",
                            }}
                          >
                            Drag & Drop or{" "}
                            <span className="browse-btn">Browse</span>
                          </Typography>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        name="BannerFile"
                        ref={bannerInputRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleImageUpload(e, "BannerFile", "viewBannerFile")
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item container spacing={2} xs={12} md={8}>
                <Grid item xs={12} md={3}>
                  <RHFDatepicker
                    name="startDate"
                    label="Start Date"
                    disablePast={true}
                    placeholder="date"
                    className="required-field"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTimePicker
                    name="startTime"
                    label="Start Time"
                    className="required-field"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFDatepicker
                    name="endDate"
                    label="End Date"
                    disablePast={true}
                    placeholder="date"
                    className="required-field"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTimePicker
                    className="required-field"
                    name="endTime"
                    label="End Time"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RHFRadioGroup
                    name="gameType"
                    row={true}
                    label={"Game Type"}
                    options={[
                      { value: "paid", label: "Paid" },
                      { value: "cash", label: "Cash" },
                      { value: "reward", label: "Reward" },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <RHFTextField
                    name="MinPlayers"
                    label="Min Players"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="required-field"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTextField
                    name="MaxPlayers"
                    label="Max Players"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="required-field"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="image-section">
                  <div className="image-upload">
                    <label className="floating-label">Leaderboard Banner</label>
                    <div
                      onClick={() => {
                        triggerFileInput(leaderboardBannerRef);
                      }}
                    >
                      {methods.watch("LeaderboardBanner") ? (
                        <img
                          src={methods.getValues("viewLeaderFile")}
                          alt="Leaderboard Banner"
                          className="uploaded-img"
                        />
                      ) : (
                        <div className="upload-placeholder">
                          <IconButton color="primary" component="span">
                            <img
                              src={img}
                              alt="Upload Icon"
                              className="upload-icon"
                            />
                          </IconButton>
                          <Typography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 0.6)",
                            }}
                          >
                            Drag & Drop or{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                border: "1px solid rgba(40, 126, 173, 0.1)",
                                borderRadius: 8,
                                color: "rgba(40, 126, 173, 0.8)",
                                backgroundColor: "rgba(40, 126, 173, 0.1)",
                                padding: "5px",
                              }}
                            >
                              Browse
                            </span>
                          </Typography>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        name="LeaderboardBanner"
                        ref={leaderboardBannerRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleImageUpload(
                            e,
                            "LeaderboardBanner",
                            "viewLeaderFile"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack direction="row">
                  <RHFTextField
                    name="VideoURL"
                    variant="outlined"
                    label="Promo Video URL"
                    fullWidth
                    className="form-field"
                  />
                  {methods.watch("VideoURL") ? (
                    <img
                      src={playIcon}
                      alt="play"
                      onClick={() => setViewVideo(true)}
                    />
                  ) : (
                    ""
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={2}>
                <RHFTextField
                  label="Latitude"
                  name="Latitude"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <RHFTextField
                  label="Longitude"
                  name="Longitude"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>

              <Grid item container spacing={2} xs={12} md={8}>
                {methods.watch("gameType") === "paid" && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      name="RegistrationFee"
                      label="Registration Fee($)"
                      variant="outlined"
                      type="number"
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& .MuiInputBase-input": {
                          padding: "9.5px 14px",
                          height: "52px !important",
                        },

                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "rgba(40, 126, 173, 0.12)", // Red border on error
                            color: "rgba(81, 92, 99, 0.6)",
                          },
                          "&:hover fieldset": {
                            borderColor: "rgba(40, 126, 173, 0.12)", // Red border on hover if error
                          },
                          "&.Mui-focused fieldset": {
                            borderWidth: 1,
                            borderColor: "rgba(40, 126, 173, 0.12)", // Red border on focus if error
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "rgba(81, 92, 99, 1)", // Change text color here
                          fontSize: "16px", // Font size for input conten
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "rgba(81, 92, 99, 0.1)",
                          fontSize: "16px", // Font size for input conten
                        },
                      }}
                      fullWidth
                      className="required-field"
                    />
                  </Grid>
                )}
                {methods.watch("gameType") === "cash" && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      label="Total Prize Money ($)"
                      name="TotalPrizeMoney"
                      variant="outlined"
                      fullWidth
                      type="number"
                      className="form-field"
                    />
                  </Grid>
                )}
                {(methods.watch("gameType") === "paid" ||
                  methods.watch("gameType") === "cash") && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      label="Payout Ratio"
                      name="PayoutType"
                      variant="outlined"
                      fullWidth
                      className="form-field"
                    />
                  </Grid>
                )}
                {methods.watch("gameType") === "reward" && (
                  <Grid item xs={12} md={12}>
                    <Stack direction="row">
                      <RHFSelect
                        name="PlanID"
                        label="Plan Name"
                        style={{
                          color: "rgba(81, 92, 99, 1)",
                          fontSize: "14px",
                        }}
                      >
                        {activePlans?.length > 0
                          ? activePlans.map((item, idx) => {
                              return (
                                <MenuItem
                                  key={idx}
                                  sx={{
                                    fontSize: "14px",
                                    color: "rgba(81, 92, 99,1)",
                                  }}
                                  value={item.planId}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })
                          : ""}
                      </RHFSelect>
                      {methods.watch("PlanID") ? (
                        <img
                          src={planeIndIcon}
                          alt="plan"
                          onClick={() => setViewPlan(true)}
                        />
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>
              {methods.watch("Latitude") && methods.watch("Longitude") && (
                <Grid item xs={12} md={4}>
                  <LoadScript googleMapsApiKey="AIzaSyB7eYRHUhX2ACrnGw6seFkQRlQ_mzel53Q">
                    <GoogleMap
                      mapContainerStyle={{ width: "100%", height: "140px" }}
                      center={{
                        lat: parseFloat(methods.getValues("Latitude")) || 0,
                        lng: parseFloat(methods.getValues("Longitude")) || 0,
                      }}
                      zoom={10}
                    >
                      <Marker
                        position={{
                          lat: parseFloat(methods.getValues("Latitude")) || 0,
                          lng: parseFloat(methods.getValues("Longitude")) || 0,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                </Grid>
              )}
              {/* <Grid item xs={12} md={6}>
                  <RHFTextField
                    label="Featured Priority"
                    name="FeaturedPriority"
                    variant="outlined"
                    fullWidth
                    type="number"
                  />
                </Grid> */}
            </Grid>
            <div className="flex-container">
              {methods.watch("template") ? (
                <RHFCheckbox
                  name="IsUpdateTemplate"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 24, // Reduce checkbox size
                      borderRadius: "10px", // Optional: add border radius
                    },
                    "&.Mui-checked": {
                      color: "rgba(40, 126, 173, 1)", // Set the check icon color to red
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px", // Change the font size of the label
                      color: "rgba(81, 92, 99, 0.6)", // Change the color of the label
                    },
                  }}
                  label="Update Template"
                  style={{ color: "rgba(81, 92, 99, 0.6)", fontSize: "12px" }}
                />
              ) : (
                <RHFCheckbox
                  name="IsSaveasTemplate"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 24, // Reduce checkbox size
                      borderRadius: "10px", // Optional: add border radius
                    },
                    "&.Mui-checked": {
                      color: "rgba(40, 126, 173, 1)", // Set the check icon color to red
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px", // Change the font size of the label
                      color: "rgba(81, 92, 99, 0.6)", // Change the color of the label
                    },
                  }}
                  label="Save as Template"
                  style={{ color: "rgba(81, 92, 99, 0.6)", fontSize: "12px" }}
                />
              )}

              <Button
                variant="contained"
                className="submit-button"
                type="submit"
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)",
                  color: "#fff", // Ensure the text color is readable
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                  },
                  textTransform: "unset",
                }}
              >
                {"Create"}
              </Button>
            </div>
          </Box>
        </form>
      </FormProvider>
      {viewVideo ? (
        <VideoComponent
          methods={methods}
          handleClose={() => setViewVideo(false)}
        />
      ) : (
        ""
      )}
      {open ? (
        <ConfirmDialog
          confirmText={"Delete"}
          title={"Delete Template"}
          onCancel={() => setOpen(false)}
          onConfirm={handleDelete}
          content={
            <p>
              Are you sure you want to delete this game template?
              <br /> This action cannot be undone.
            </p>
          }
        />
      ) : (
        ""
      )}
      {viewPlan ? (
        <ViewPlanPopup
          open={viewPlan}
          handleClose={() => setViewPlan(false)}
          id={methods.getValues("PlanID")}
        />
      ) : (
        ""
      )}
    </div>
  );
}
