import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import edit from "../../../assets/svg/edit-icon.svg";
import "../../../styles/global.css";
import "./games.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import renderParticipants from "../../../components/ProgressBar/ProgressBar";
import moment from "moment";
import Spinner from "../../../components/Spinner";
import CustomTable from "../../../components/MuiTable/CustomTable";
import { useGames } from "../../../queries/games";

export default function Games() {
  const [filter, setFilter] = useState("OPEN");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const navigate = useNavigate();

  const { games, isLoading } = useGames(filter, page, pageSize);

  function getGameType(game) {
    let fee = parseFloat(game?.RegistrationFee || 0);
    let prize = game?.TotalPrizeMoney
      ? parseFloat(game.TotalPrizeMoney).toFixed(2)
      : 0;
    let payout = game?.PayoutType?.length > 0;
    let plan = game?.PlanName?.length > 0;
  
    if (game.GameID == 31477) {
      console.log(typeof fee, "FEEEE", fee);
      console.log(fee !== 0);
    }
  
    if (fee !== 0 && payout) {
      return "Paid";
    } else if (plan && fee == 0) {
      return "Reward";
    } else if (prize !== 0 && payout && fee==0) {
      return "Cash Prize";
    }
  
    return "";
  }
  
  const rows =
    games?.Table?.map((game) => ({
      ...game,
      id: game.GameID,
      RegistrationFee: parseFloat(game.RegistrationFee).toFixed(2),
      TotalPrizeMoney: parseFloat(game.TotalPrizeMoney).toFixed(2),
      StartDateTime: moment(game.StartDateTime).format("M/D/YYYY h:mm A"),
      EndDateTime: moment(game.EndDateTime).format("M/D/YYYY h:mm A"),
      GameType: getGameType(game), 
    })) || [];

  const totalRecords = games?.Table?.[0]?.Count || 0;
 
  const getColumns = () => {
    const columns = [
      { field: "GameID", headerName: "#ID", width: 100 },
      { field: "Description", headerName: "Name", width: 190 },
      { field: "GameType", headerName: "Game Type", width: 100},
      { field: "RegistrationFee", headerName: "Registration Fee", width: 150, headerAlign: "right", align: "right",
        renderCell: (params) => {
          const valueFormatted = params.value!=='0.00'?`$${params.value}`:'' 
          return valueFormatted;
        }
      },
      {
        field: "PlanName",
        headerName: "Plan Name",
        width: 170,
        renderCell: (params) => {              
          return  params.value?params.value:''
        }
      },
    ];

    switch (filter) {
      case "OPEN":
        columns.push(
          { field: "StartDateTime", headerName: "Start Date Time", width: 180 },
          { field: "EndDateTime", headerName: "End Date Time", width: 180},
          {
            field: "TotalPrizeMoney",
            headerName: "Total Prize",
            width: 100,
            headerAlign: "right",
            align: "right",
            renderCell: (params) => {
              const valueFormatted = params.value!=='0.00'?`$${params.value}`:'' 
              return valueFormatted;
            }
          },
          {
            field: "PayoutType",
            headerName: "Payout Type",
            width: 150,
            headerAlign: "right",
            align: "right",
          },
        
          {
            field: "NumRegistered",
            headerName: "Participants",
            width: 150,
            align: "right",
            renderCell: renderParticipants,
          },
          {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            align: "right",
            width: 100,
            sortable: false,
            renderCell: renderActions,
          }
        );
        break;

      case "RUNNING":
      case "COMPLETED": // Similar columns for both "RUNNING" and "COMPLETED"
        columns.push(
          { field: "EndDateTime", headerName: "End Date Time", width: 180  },
          {
            field: "TotalPrizeMoney",
            headerName: "Total Prize Money",
            headerAlign: "right",
            width: 150,
            align: "right",
            renderCell: (params) => {
              const valueFormatted = params.value!=='0.00'?`$${params.value}`:'' 
              return valueFormatted;
            }
          },
          {
            field: "NumRegistered",
            headerName: "Participants",
            headerAlign: "right",
            width: 150,
            align: "right",
          },
          {
            field: "PayoutType",
            headerName: "Payout Type",
            width: 150,
            headerAlign: "right",
            align: "right",
          },
          {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            align: "right",
            width: 150,
            sortable: false,
            renderCell:
              filter === "RUNNING"
                ? renderCompletedActions
                : renderCompletedActions, 
          }
        );
        break;

      case "CANCELLED":
        columns.push(
          { field: "StartDateTime", headerName: "Start Date Time",width: 180, },
          { field: "EndDateTime", headerName: "End Date Time",width: 180, },
          {
            field: "NumRegistered",
            headerName: "Participants",
            width: 150,
            renderCell: renderParticipants,
          },
          
          {
            field: "TotalPrizeMoney",
            headerName: "Total Prize",
            headerAlign: "right",
            width: 150,
            align: "right",
            renderCell: (params) => {
              const valueFormatted = params.value!=='0.00'?`$${params.value}`:'' 
              return valueFormatted;
            }
          }
        );
        break;

      default:
        return columns;
    }

    return columns;
  };

  const renderActions = (params) => (
    <div style={{ textAlign: "center", width: "100%" }}>
      <img
        onClick={() => navigate(`/edit_game/${params.row.GameID}`)}
        src={edit}
        className="cursor-pointer action-icon"
        alt="Edit"
        style={{
          marginRight: 10,
          cursor: "pointer",
          transition: "background-color 0.3s, transform 0.3s",
          borderRadius: "4px",
          padding: "4px",
          border: "1px solid transparent",
        }}
      />
    </div>
  );

  const renderCompletedActions = (params) => (
    <div style={{ textAlign: "center", width: "100%" }}>
      <IconButton onClick={() => navigate(`/edit_game/${params.row.GameID}`)}>
        <RemoveRedEyeIcon />
      </IconButton>
    </div>
  );

  return (
    <div className="all-games-view">
      <div className="all-games-inner-view">
        <Typography fontFamily={"Lato"} fontSize={28} fontWeight={700}>
          {filter == "OPEN"
            ? "Open"
            : filter == "RUNNING"
            ? "Running"
            : filter == "COMPLETED"
            ? "Completed"
            : "Cancelled"}{" "}
          Games
        </Typography>
        <div className="header-filter">
          <div className="search-view">
            <Typography
              flex={1}
              display={"inline"}
              marginRight={1}
              color="rgba(81, 92, 99, 1)"
              fontFamily={"Lato"}
              fontSize={12}
              fontWeight={400}
              className="filter-label-text"
            >
              Filter By:
            </Typography>
            <FormControl
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(40, 126, 173, 0.12)", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(40, 126, 173, 0.12)", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(40, 126, 173, 0.12)", // Focus border color
                  },
                },
              }}
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginRight: 10, float: "none" }}
            >
              <Select
                value={filter}
                sx={{ fontSize: "14px", color: "rgba(81, 92, 99, 0.8)" }}
                onChange={(e) => {
                  setPage(0);
                  setPageSize(100);
                  setFilter(e.target.value);
                }}
                displayEmpty // Ensures an empty placeholder is shown
              >
                <MenuItem sx={{ fontSize: "14px" }} value="OPEN">
                  Open
                </MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="RUNNING">
                  Running
                </MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="COMPLETED">
                  Completed
                </MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="CANCELLED">
                  Cancelled
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              onClick={() => navigate("/create_game")}
              variant="contained"
              style={{
                textTransform: "unset",
                backgroundColor: "#287EAD",
                width: "100%",
              }}
            >
              Create Game
            </Button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="table-loader"><Spinner /></div>
      ) : (
        <div className="table-wrapper">
          <div className="table-container">
            <CustomTable
              initialRows={rows}
              headerBgColor={"rgba(40, 126, 173, 0.05)"}
              columns={getColumns()}
              defaultSort={"GameID"}
              subHeight={230}
              rowCount={totalRecords}
              setPaginationModel={(data) => {
                setPage(data.page);
                setPageSize(data.pageSize);
              }}
              page={page}
              pageSize={pageSize}
              onPageChange={(newPage) => {
                console.log("newPage", newPage);
                setPage(newPage);
              }}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
}
