import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import reward from "../assets/svg/reward.png";
import game from "../assets/svg/game-icon.png";

import useMediaQuery from "@mui/material/useMediaQuery";
import header from "../assets/svg/header.svg";
import MenuIcon from "@mui/icons-material/Menu";
import "./Sidebar.css";
import { Avatar, makeStyles } from "@mui/material";
import { useState } from "react";
const drawerWidth = 240;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width:600px)");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleListItemClick = (index) => {
    setActiveItem(index);
  };

  const handleLogout = () => {
    console.log('Logging out...');
    localStorage.clear();
    navigate('/');
  };

  return (
    <Box sx={{ display: "flex" }}>
      {!isMobile && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#06002C",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <img
                src={header}
                alt="Logo"
                style={{ alignSelf: "center", margin: "20px" }}
              />
            </Box>
          </Toolbar>

          <List sx={{}}>
            <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.16" }} />
            {[
              { text: "All Games", route: "/games", img: game },
              { text: "Rewards", route: "/rewards", img: reward },
            ].map((item, index) => (
              <ListItem
              disablePadding
              key={`sidebar-nav-${index}`}              sx={{
                backgroundColor: activeItem === index ? "#287EAD" : "#06002C",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor:
                      activeItem === index ? "#287EAD" : "#lightblue",
                  },
              }}
            >
             <NavLink
                  activeClassName="active"
                  to={item.route}
                  className={`sidebar-nav-link`}
                >
                  <img src={item.img} alt="not found" />{" "}
                  <ListItemText
                    style={{ paddingLeft: 10 }}
                    primary={item.text}
                  />
                </NavLink>
            </ListItem>
            
            ))}
            <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.16" }} />
          </List>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ p: 2 }}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Avatar style={{ marginRight: 10 }} />
              <Typography color={"white"} variant="body1" gutterBottom>
                DTC Admin
                <Typography onClick={handleLogout} variant="body1" gutterBottom>
                  Logout
                </Typography>
              </Typography>
            </div>
          </Box>
        </Drawer>
      )}

      {isMobile && (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ position: "absolute", top: 16, left: 16 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            sx={{
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                backgroundColor: "#06002C",
              },
            }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Toolbar>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <img src={header} alt="Logo" style={{ marginRight: "auto" }} />
              </Box>
            </Toolbar>
            <Divider />
            <List className="list">
              <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/games">
                  <img src={game} />

                  <ListItemText primary="All Games" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/rewards">
                  <img src={reward} />

                  <ListItemText primary="Rewards" />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ p: 2 }}>
              <Typography variant="body1" gutterBottom>
                DTC Admin
              </Typography>
              <IconButton onClick={handleLogout} color="primary">
                <LogoutIcon />
              </IconButton>
            </Box>
          </Drawer>
        </>
      )}
    </Box>
  );
};

export default Sidebar;
