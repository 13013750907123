import React from "react";
import GameComponent from "../../../../components/GameComponent";
import {  useParams } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import { useGameDetail } from "../../../../queries/gameDetails";
import { useGroupIds } from "../../../../queries/groupId";
import { useActivePlans } from "../../../../queries/activePlans";

function EditGame() {
  const { id } = useParams();
  const { data, isLoading } = useGameDetail(id);
  const { groupIds } = useGroupIds();
  const { activePlans} = useActivePlans()
  const games = data?.data?.Table[0];

  function getGameType(game) {
    let fee = parseFloat(game?.RegistrationFee || 0);
    let prize = parseFloat(game?.TotalPrizeMoney || 0);

    let payout = game?.PayoutType?.length > 0;
    let plan = game?.PlanId?.length > 0||game?.PlanID?.length>0

    if (fee !== 0 && payout) {
      return "paid";
    } else if (prize !== 0 && payout && fee==0) {
      return "cash";
    }
    else if (plan) {
      return "reward";
    }
    return ''
  }

  let game = {
    ...games,
    gameType: getGameType(games),
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <GameComponent gameData={game} groups={groupIds} plans={activePlans} id={id} />
  );
}

export default EditGame;
