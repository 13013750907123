import * as yup from "yup";

export const createRewardSchemas = yup
  .object({
    PlanId: yup.string().required("Plan id is required"),
    passcode: yup.string().required("Passcode is required"),
    Name: yup.string().required("Reward name is required"),
    RewardExpiryDate: yup.string().when("validity", (type, schema) => {
      return type == "expiry"
        ? schema.required("Expiration date is required")
        : schema.notRequired();
    }),
    validity: yup.string().required("validity required"),
    days: yup.string().when("validity", (type, schema) => {
      return type == "days"
        ? schema.required("Days are required")
        : schema.notRequired();
    }),
    RewardDetails: yup
      .array()
      .of(
        yup.object().shape({
          Id: yup.number(),
          Position: yup.number(),
          StartPosition: yup
            .number()
            .required("Position is required")
            .positive("Position must be positive")
            .typeError("Position is required"),

          RedeemPasscode: yup.string(),
          Description: yup.string().required("Description is required"),
        })
      )
      .min(1, "At least one reward detail is required"),
  })
  .required();
