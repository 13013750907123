import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const RHFTimePicker = ({ name, label,className,disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          label={label}
          value={field.value}
          disabled={disabled}
          className={className}
          onChange={(time) => {
            field.onChange(time);
          }}
          sx={{
            "& .MuiInputBase-root": {
              '& fieldset': {
                borderColor: 'rgba(40, 126, 173, 0.12)',  // Default border color
              },
              height: "52px !important",
              backgroundColor: "#fff",
              color:'rgba(81, 92, 99, 1)',
              fontSize:'16px',
              '&:hover fieldset': {
                borderColor: 'rgba(40, 126, 173, 0.12)',  // Hover border color
              },
            },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(40, 126, 173, 0.12)',  // Focus border color
              },
              "& .MuiSvgIcon-root": {
                color:'rgba(81, 92, 99, 0.6)', 
                fontSize:'18px' // Custom icon color
              }
          }}
        
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "outlined",
              error: !!error,
              helperText: error?.message,
              style: { height: "52px !important",fontSize:'14px' },
              InputLabelProps: {
                style: { 
                  fontSize: '15px', // Adjust font size
                  color:'rgba(81, 92, 99, 0.8)' // Custom color for the label
                },

              },
              InputProps: {
                readOnly: disabled, // Ensure the input is not editable
              },

            },
          }}
          {...field}
        />
      )}
    />
  );
};

export default RHFTimePicker;