import { useQuery } from "react-query";
import { getActivePlans } from "../services/rewards/apiGetActivePlans";

export const useActivePlans = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["plans"],
    queryFn: () => getActivePlans(),
  });
  const activePlans = data?.data;
  console.log(activePlans,'Planssss')
  return { activePlans, isLoading };
};
