import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { editRewardSchemas } from "../../../../schemas/rewards/editReward";
import { Box, Button, Grid, MenuItem, Stack, Typography } from "@mui/material";
import RHFTextField from "../../../../components/RHF/RHFTextfield";
import { RHFSelect } from "../../../../components/RHF/RHFSelect";
import deleteStepIcon from "../../../../styles/images/delete_reward_step.svg";
import RHFDatepicker from "../../../../components/RHF/RHFDatepickers";
import dayjs from "dayjs";
import { putEditReward } from "../../../../services/rewards/apiUpdateReward";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { LoadingButton } from "@mui/lab";
import RHFRadioGroup from "../../../../components/RHF/RHFRadiogroup";
import moment from "moment";

function EditRewardForm({ data, handleClose, selectedQuery }) {
  console.log("edit details === ", data);
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(false);

  const methods = useForm({
    resolver: yupResolver(editRewardSchemas),
    defaultValues: {
      ...data,
      passcode: data.rewardDetails[0].redeemPasscode,
      validity: "expiry",
      days: "",
      rewardDetails: data.rewardDetails.map((ele) => ({
        startPosition: ele.startPosition,
        endPosition: ele.endPosition,
        description: ele.description,
        prizeMoney: ele.prizeMoney,
        redeemPasscode: ele.redeemPasscode,
      })),
      rewardExpiryDate: dayjs(data.rewardExpiryDate),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "rewardDetails",
  });

  const onSubmit = (formData) => {
    console.log("form data == ", formData);
    const rewardData = {
      PlanId: Number(formData.planId),
      passcode: formData.passcode,
      Name: formData.name,
      RewardType: "NONCASH",
      RewardExpiryDate: formData.rewardExpiryDate,
      RewardDetails: formData.rewardDetails.map((ele) => ({
        Id: 0,
        Position: Number(0),
        StartPosition: ele.startPosition,
        EndPosition: ele.startPosition,
        RedeemPasscode: formData.passcode,
        Description: "",
        amount: "",
        PrizeMoney: 0,
      })),
    };
    if (formData.days) {
      const today = moment();
      const futureDate = today.add(formData.days, "days");
      rewardData.rewardExpiryDate = futureDate;
    }
    delete formData.days;
    formData.endPosition = formData.startPosition;
    setLoading(true);
    putEditReward(rewardData)
      .then((res) => {
        console.log("put edit reward == ", res.data);
        toast.success(res.data);
        queryClient.invalidateQueries({
          queryKey: ["rewards", selectedQuery.page, selectedQuery.pageSize],
        });
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        console.log("put edit reward error == ", err);
      });
  };

  console.log("errors of react-hook-form", methods.formState.errors);
  console.log("fields ==== ", fields);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RHFTextField name="planId" disabled={true} />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField name="passcode" label="Passcode" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <RHFTextField name="name" fullWidth label="Reward Name" focused />
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="#1B3E61"
              marginBottom={1}
              fontFamily={"Lato"}
              fontSize={16}
              fontWeight={600}>
              Reward Range
            </Typography>
            {methods.formState.errors.rewardDetails?.message && (
              <Typography color="#d32f2f" fontWeight={400} fontSize={12} mb={1}>
                {methods.formState.errors.rewardDetails.message}
              </Typography>
            )}
          </Grid>
          {fields.map((field, index) => (
            <Box m={2} key={field.id} width="100%">
              <Stack spacing={2} direction="row" fullWidth>
                <RHFTextField
                  name={`rewardDetails[${index}].startPosition`}
                  InputProps={{ inputProps: { min: 0 } }}
                  type="number"
                  label="Position"
                />
                <RHFTextField
                  name={`rewardDetails[${index}].description`}
                  label="Description"
                  sx={{
                    flexGrow: "1",
                  }}
                />
                <img
                  src={deleteStepIcon}
                  alt="delete step"
                  onClick={() => {
                    remove(index);
                  }}
                />
              </Stack>
            </Box>
          ))}

          <Button
            onClick={() =>
              append({
                id: fields.length + 1,
                position: 0,
                startPosition: 0,
                endPosition: 0,
                redeemPasscode: "",
                description: "",
                amount: "",
                prizeMoney: 0,
              })
            }
            variant="contained"
            style={{
              color: "gray",
              fontSize: 17,
              textTransform: "unset",
              border: "1px dashed lightgrey",
              backgroundColor: "#287EAD05",
              width: "100%",
              boxShadow: "none",
              marginLeft: "16px",
            }}>
            + Add
          </Button>
          <Grid item xs={12}>
            <RHFRadioGroup
              name="validity"
              row={true}
              label="Validity"
              options={[
                { value: "expiry", label: "Expiry date" },
                { value: "days", label: "Validity of days" },
              ]}
            />
          </Grid>
          {methods.watch("validity") == "days" ? (
            <Grid item xs={12}>
              <RHFTextField name="days" label="Days" />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <RHFDatepicker name="rewardExpiryDate" fullWidth />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <LoadingButton
                loading={isLoading}
                variant="contained"
                className="submit-button"
                type="submit"
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                  color: "#fff", // Ensure the text color is readable
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                  },
                  textTransform: "unset",
                }}>
                Update Reward
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default EditRewardForm;
