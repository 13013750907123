import React from "react";
import { LinearProgress, Tooltip, Box, Typography } from "@mui/material";

export default function renderParticipants(params) {
  const { NumRegistered, MinPlayers, MaxPlayers } = params.row;

  // Ensure valid numbers
  const numRegistered = parseInt(NumRegistered) || 0;
  const minPlayers = parseInt(MinPlayers) || 0;
  const maxPlayers = parseInt(MaxPlayers) || 100; // Assuming MaxPlayers is 100 by default

  // Calculate the percentage
  const percentage = Math.min((numRegistered / maxPlayers) * 100, 100);

  // Determine color: red if below MinPlayers, green otherwise
  const progressColor = numRegistered >= minPlayers ? "green" : "red";
 
  // Tooltip content
  const tooltipContent = `${numRegistered}-${minPlayers}/${maxPlayers}`;

  return (
    <Tooltip title={tooltipContent}>
      <Box alignItems="center" width="100%" display="flex" flexDirection="column">
        {/* Progress bar */}
        <Box width="100%" sx={{ position: 'relative' }}>
          <LinearProgress
            variant="determinate"
            value={percentage}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: "#f0f0f0",
              "& .MuiLinearProgress-bar": {
                backgroundColor: progressColor, // Apply the dynamic color here
              },
            }}
          />
        </Box>
      </Box>
    </Tooltip>
  );
}
