import {
  Box,
  MenuItem,
  Button,
  IconButton,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import "../styles/gamedetail.css";
import img from "../assets/svg/image-picker-icon.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { RHFSelect } from "./RHF/RHFSelect";
import RHFTextField from "./RHF/RHFTextfield";
import RHFDatepicker from "./RHF/RHFDatepickers";
import { RHFCheckbox } from "./RHF/RHFCheckbox";
import RHFTimePicker from "./RHF/RHFTimepicker";
import dayjs from "dayjs";
import playIcon from "../assets/svg/play.svg";
import planeIndIcon from "../styles/images/planId_icon.svg";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { updateGame } from "../services/game/updateGame";
import { updateGameStatus } from "../services/game/apiUpdateGameStatus";
import Spinner from "./Spinner";
import toast from "react-hot-toast";
import VideoComponent from "./VideoPopup/VideoPopup";
import ViewPlanPopup from "../pages/layout/games/viewPlanId";
import LeaderboardTable from "./LeaderboardTable";
import { getLeaderboard } from "../services/game/apiLeaderboard";
import ConfirmDialog from "./ConfirmDialog";
import { useNavigate } from "react-router-dom";
import RHFTextEditor from "./RHF/RHFTexteditor";
import { createGameSchema } from "../schemas/game/createGame";
import RHFRadioGroup from "./RHF/RHFRadiogroup";
import { useQueryClient } from "react-query";

export default function GameComponent({ gameData, groups, plans, id }) {
  const queryClient = useQueryClient();
  const methods = useForm({
    resolver: yupResolver(createGameSchema),
    defaultValues: {
      Description: gameData?.Description || "",
      GroupID: gameData?.GroupID,
      GameDetail: gameData?.GameDetail,
      MinPlayers: gameData?.GameDetail ? +gameData.MinPlayers : "",
      MaxPlayers: gameData?.MaxPlayers ? +gameData.MaxPlayers : "",
      VideoURL: gameData?.VideoURL,
      RegistrationFee: gameData?.RegistrationFee
        ? (+gameData.RegistrationFee).toFixed(2)
        : "",
      TotalPrizeMoney: gameData?.TotalPrizeMoney
        ? (+gameData.TotalPrizeMoney).toFixed(2)
        : "",
      PayoutType: gameData?.PayoutType,
      PlanID: gameData?.PlanID,
      Latitude: gameData?.Latitude,
      Longitude: gameData?.Longitude,
      IsSaveasTemplate: gameData?.IsSaveasTemplate,
      isFeatured: gameData?.isFeatured,
      BannerFile: gameData?.BannerFile,
      LeaderboardBanner: gameData?.LeaderboardBanner,
      viewLeaderFile: gameData?.BannerFile,
      viewBannerFile: gameData?.LeaderboardBanner,
      startDate: gameData?.StartDateTime ? dayjs(gameData.StartDateTime) : "",
      endDate: gameData?.EndDateTime ? dayjs(gameData.EndDateTime) : "",
      startTime: gameData?.StartDateTime ? dayjs(gameData.StartDateTime) : "",
      endTime: gameData?.EndDateTime ? dayjs(gameData.EndDateTime) : "",
      Status: gameData?.Status ? gameData.Status : "",
      gameType: gameData?.gameType,
    },
  });
  console.log(gameData, "-----------gameData");
  const statusOptions = [
    { key: "Open", value: "OPEN" },
    { key: "Cancelled", value: "CANCELLED" },
  ];
  const navigate = useNavigate();
  const [viewPlan, setViewPlan] = useState(false);
  const [viewVideo, setViewVideo] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const bannerInputRef = useRef(null);
  const leaderboardBannerRef = useRef(null);

  const [leaderboardData, setLeaderboardData] = useState([]);
  useEffect(() => {
    if (gameData?.Status === "COMPLETED" || gameData?.Status === "RUNNING") {
      setLoading(true);
      getLeaderboard({ GameID: id })
        .then((res) => {
          setLeaderboardData(res);
          console.log(res, "RESSPINSE FROM API");
        })
        .catch((er) => {
          console.log(er, "Error in fetching leaderboard");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLeaderboardData([]);
    }
  }, []);
  useEffect(() => {
    const gameType = methods.watch("gameType");

    if (gameType == "cash") {
      methods.setValue("PlanID", "");
    }
    if (gameType != "paid") {
      methods.setValue("RegistrationFee", "0");
    }

    if (gameType != "cash") {
      methods.setValue("TotalPrizeMoney", "0");
    }
    if (gameType == "cash" || gameType == "paid") {
      methods.setValue("PlanID", "");
    }
    if (gameType === "reward") {
      methods.setValue("PayoutType", "");
    }
  }, [methods.watch("gameType")]);

  const handleImageUpload = (event, key, viewFile) => {
    const file = event.target.files[0];

    if (file) {
      methods.setValue(key, file);
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("result of image == ", reader.result);
        methods.setValue(viewFile, reader.result);
        console.log("image file === ", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onSubmit = () => {
    const startDate = methods.getValues("startDate");
    const endDate = methods.getValues("endDate");
    const startTime = methods.getValues("startTime");
    const endTime = methods.getValues("endTime");

    const StartDateTime = dayjs(
      `${dayjs(startDate).format("YYYY-MM-DD")} ${dayjs(startTime).format(
        "HH:mm:ss.SSS"
      )}`
    ).format("YYYY-MM-DD HH:mm:ss.SSS");

    const EndDateTime = dayjs(
      `${dayjs(endDate).format("YYYY-MM-DD")} ${dayjs(endTime).format(
        "HH:mm:ss.SSS"
      )}`
    ).format("YYYY-MM-DD HH:mm:ss.SSS");

    const GameID = gameData?.GameID;
    const MaxPlayers = parseInt(methods.getValues("MaxPlayers"));
    const MinPlayers = parseInt(methods.getValues("MinPlayers"));
    const RegistrationFee =
      parseFloat(methods.getValues("RegistrationFee")) || 0;
    const Latitude = methods.getValues("Latitude")
      ? parseFloat(methods.getValues("Latitude"))
      : "";
    const Longitude = methods.getValues("Longitude")
      ? parseFloat(methods.getValues("Longitude"))
      : "";
    const formData = {
      Description: methods.getValues("Description"),
      GroupID: methods.getValues("GroupID"),
      GameDetail: methods.getValues("GameDetail"),
      MinPlayers,
      MaxPlayers,
      VideoURL: methods.getValues("VideoURL"),
      RegistrationFee,
      TotalPrizeMoney: methods.getValues("TotalPrizeMoney") || "0",
      PayoutType: methods.getValues("PayoutType"),
      PlanID: methods.getValues("PlanID"),
      Latitude,
      Longitude,
      LeaderboardBanner: methods.getValues("LeaderboardBanner"),
      BannerFile: methods.getValues("BannerFile"),
      StartDateTime,
      EndDateTime,
      GameID,
    };

    console.log("form data with datetime === ", formData);
    setLoading(true);
    updateGame(formData)
      .then((res) => {
        console.log("create game response === ", res);
        toast.success("Game Updated Successfully.");
        queryClient.invalidateQueries({
          queryKey: ["getGames"]
        })
        queryClient.invalidateQueries({
          queryKey: ["gameDetails",id]
        })
        
        console.log(gameData?.GameID, "gameData?.GameID");
        onUpdateGameStatus();
      })
      .catch((err) => {
        const status = err.response.status;
        setLoading(false);
        if (status === 400) {
          toast.error("Bad Request. Please check your input and try again.");
        } else if (status === 401) {
          toast.error("Unauthorized. Please login again.");
        } else if (status === 500) {
          toast.error("Server Error. Please try again later.");
        } else {
          toast.error(
            `Error: ${err.response.statusText || "Something went wrong."}`
          );
        }
        console.log("create game error == ", err);
      })
      .finally(() => {});
  };
  const onUpdateGameStatus = () => {
    setLoading(true);
    let data = {
      GameID: gameData?.GameID,
      Status: methods.getValues("Status"),
    };
    console.log(methods.getValues("Status"), "methods.getValues");
    updateGameStatus(data)
      .then((res) => {
        toast.success("Game status updated");
        navigate("/games");
        setLoading(false);
      })
      .catch((er) => {
        toast.error("Error in updating the game status");
        console.log(er, "Error in Updating game status");
        setLoading(false);
      });
  };
  // Function to check if the game can be edited based on its status
  const canEditGame = (status) => {
    switch (status) {
      case "OPEN":
        if (gameData?.NumRegistered > 0) {
          return {
            canEdit: true, // Allow editing
            canUpdateFees: false, // Cannot update registration fees
          };
        } else {
          return {
            canEdit: true, // Allow editing
            canUpdateFees: true, // Cannot update registration fees
          };
        }

      case "RUNNING":
        return {
          canEdit: false, // Allow editing
          canUpdateFees: false, // Cannot update registration fees
        };

      case "COMPLETED":
        return {
          canEdit: false, // Cannot edit
          canUpdateFees: false, // Cannot update registration fees
        };
      case "CANCELLED":
        return {
          canEdit: false, // Cannot edit
          canUpdateFees: false, // Cannot update registration fees
        };
      default:
        return {
          canEdit: true, // Allow editing for unknown statuses
          canUpdateFees: true, // Allow updating registration fees for unknown statuses
        };
    }
  };

  const status = gameData?.Status;
  const { canEdit, canUpdateFees } = canEditGame(status);
  const isDisable = !canEdit;
  const disableFees = !canUpdateFees;

  if (isLoading) {
    return <Spinner />;
  }

  // console.log("errors of edit game == ", methods.formState.errors);
  return (
    <div>
      <Typography fontFamily={"Lato"} fontSize={28} fontWeight={700} mb={2}>
        Game Detail
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box className="game-detail-container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <RHFTextField
                  disabled={isDisable}
                  InputProps={{
                    readOnly: isDisable,
                  }}
                  name="Description"
                  label="Game Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {groups?.length > 0 && (
                  <RHFSelect
                    disabled={isDisable}
                    name="GroupID"
                    label="Group ID"
                    style={{
                      color: "rgba(81, 92, 99, 1)",
                      fontSize: "16px",
                    }}
                  >
                    {groups?.map((item) => {
                      return (
                        <MenuItem
                          sx={{
                            fontSize: "15px",
                            color: "rgba(81, 92, 99,1)",
                          }}
                          value={item.GroupID}
                        >
                          {item.GroupID}
                        </MenuItem>
                      );
                    })}
                  </RHFSelect>
                )}
              </Grid>

              <Grid item xs={12} md={8} height={106}>
                <RHFTextEditor
                  name="GameDetail"
                  placeholder="Enter game details"
                  readOnly={isDisable}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="image-section">
                  <div className="image-upload">
                    <label className="floating-label">Banner Image</label>
                    <div
                      onClick={() => {
                        triggerFileInput(bannerInputRef);
                      }}
                    >
                      {methods.watch("BannerFile") ? (
                        <img
                          src={methods.getValues("viewBannerFile")}
                          alt="Banner"
                          className="uploaded-img"
                        />
                      ) : (
                        <div className="upload-placeholder">
                          <img
                            src={img}
                            alt="Upload Icon"
                            className="upload-icon"
                          />
                          <Typography
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 0.6)",
                            }}
                          >
                            Drag & Drop or{" "}
                            <span className="browse-btn">Browse</span>
                          </Typography>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        disabled={isDisable}
                        name="BannerFile"
                        ref={bannerInputRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleImageUpload(e, "BannerFile", "viewBannerFile")
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item container spacing={2} xs={12} md={8}>
                <Grid item xs={12} md={3}>
                  <RHFDatepicker
                    InputProps={{ readOnly: isDisable }}
                    name="startDate"
                    label="Start Date"
                    placeholder="date"
                    disabled={isDisable}
                    disablePast={true}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTimePicker
                    disabled={isDisable}
                    name="startTime"
                    label="Start Time"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFDatepicker
                    InputProps={{ readOnly: isDisable }}
                    name="endDate"
                    label="End Date"
                    placeholder="date"
                    disabled={isDisable}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTimePicker
                    disabled={isDisable}
                    name="endTime"
                    label="End Time"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RHFRadioGroup
                    name="gameType"
                    row={true}
                    label={"Game Type"}
                    options={[
                      { value: "paid", label: "Paid" },
                      { value: "cash", label: "Cash" },
                      { value: "reward", label: "Reward" },
                    ]}
                    disabled={isDisable}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTextField
                    disabled={isDisable}
                    InputProps={{ readOnly: isDisable }}
                    name="MinPlayers"
                    label="Min Players"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="form-field"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTextField
                    disabled={isDisable}
                    InputProps={{
                      readOnly: isDisable,
                    }}
                    name="MaxPlayers"
                    label="Max Players"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="form-field"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="image-section">
                  <div className="image-upload">
                    <label className="floating-label">Leaderboard Banner</label>
                    <div
                      onClick={() => {
                        triggerFileInput(leaderboardBannerRef);
                      }}
                    >
                      {methods.watch("LeaderboardBanner") ? (
                        <img
                          src={methods.getValues("viewLeaderFile")}
                          alt="Leaderboard Banner"
                          className="uploaded-img"
                        />
                      ) : (
                        <div className="upload-placeholder">
                          <IconButton color="primary" component="span">
                            <img
                              src={img}
                              alt="Upload Icon"
                              className="upload-icon"
                            />
                          </IconButton>
                          <Typography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 0.6)",
                            }}
                          >
                            Drag & Drop or{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                border: "1px solid rgba(40, 126, 173, 0.1)",
                                borderRadius: 8,
                                color: "rgba(40, 126, 173, 0.8)",
                                backgroundColor: "rgba(40, 126, 173, 0.1)",
                                padding: "5px",
                              }}
                            >
                              Browse
                            </span>
                          </Typography>
                        </div>
                      )}
                      <input
                        type="file"
                        disabled={isDisable}
                        accept="image/*"
                        name="LeaderboardBanner"
                        ref={leaderboardBannerRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleImageUpload(
                            e,
                            "LeaderboardBanner",
                            "viewLeaderFile"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack direction={"row"}>
                  <RHFTextField
                    disabled={isDisable}
                    InputProps={{
                      readOnly: isDisable,
                    }}
                    name="VideoURL"
                    variant="outlined"
                    label="Promo Video URL"
                    fullWidth
                    className="form-field"
                  />
                  {methods.watch("VideoURL") ? (
                    <img
                      src={playIcon}
                      alt="play"
                      onClick={() => setViewVideo(true)}
                    />
                  ) : (
                    ""
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={2}>
                <RHFTextField
                  disabled={isDisable}
                  InputProps={{
                    readOnly: isDisable,
                  }}
                  label="Latitude"
                  name="Latitude"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <RHFTextField
                  disabled={isDisable}
                  InputProps={{
                    readOnly: isDisable,
                  }}
                  label="Longitude"
                  name="Longitude"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>
              <Grid item container spacing={2} xs={12} md={8}>
                {methods.watch("gameType") === "paid" && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      disabled={disableFees || isDisable} // Disable if not allowed to update fees
                      name="RegistrationFee"
                      label="Registration Fee($)"
                      variant="outlined"
                      type="number"
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& .MuiInputBase-input": {
                          padding: "9.5px 14px",
                          height: "52px !important",
                        },

                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "rgba(40, 126, 173, 0.12)", // Red border on error
                            color: "rgba(81, 92, 99, 0.6)",
                          },
                          "&:hover fieldset": {
                            borderColor: "rgba(40, 126, 173, 0.12)", // Red border on hover if error
                          },
                          "&.Mui-focused fieldset": {
                            borderWidth: 1,
                            borderColor: "rgba(40, 126, 173, 0.12)", // Red border on focus if error
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "rgba(81, 92, 99, 1)", // Change text color here
                          fontSize: "16px", // Font size for input conten
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "rgba(81, 92, 99, 0.1)",
                          fontSize: "16px", // Font size for input conten
                        },
                      }}
                      fullWidth
                      className="required-field"
                    />
                  </Grid>
                )}

                {methods.watch("gameType") === "cash" && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      disabled={isDisable}
                      InputProps={{
                        readOnly: isDisable,
                      }}
                      label="Total Prize Money ($)"
                      name="TotalPrizeMoney"
                      variant="outlined"
                      fullWidth
                      className="form-field"
                    />
                  </Grid>
                )}
                {(methods.watch("gameType") === "paid" ||
                  methods.watch("gameType") === "cash") && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      disabled={isDisable}
                      InputProps={{
                        readOnly: isDisable,
                      }}
                      label="Payout Ratio"
                      name="PayoutType"
                      variant="outlined"
                      fullWidth
                      className="form-field"
                    />
                  </Grid>
                )}

                {methods.watch("gameType") === "reward" && (
                  <Grid item xs={12} md={12}>
                    <Stack direction={"row"}>
                      {plans?.length > 0 && (
                        <RHFSelect
                          disabled={isDisable}
                          name="PlanID"
                          label="Plan Name"
                          style={{ color: "rgba(81, 92, 99, 1)" }}
                        >
                          {plans?.map((item) => (
                            <MenuItem
                              sx={{
                                fontSize: "15px",
                                color: "rgba(81, 92, 99, 1)",
                              }}
                              key={item.planId}
                              value={item.planId}
                            >
                              {item.name}---{item.planId}
                            </MenuItem>
                          ))}
                        </RHFSelect>
                      )}

                      {methods.watch("PlanID") ? (
                        <img
                          src={planeIndIcon}
                          alt="plan"
                          onClick={() => setViewPlan(true)}
                        />
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={12} md={3}>
                  {statusOptions?.length > 0 && (
                    <RHFSelect
                      name="Status"
                      disabled={isDisable}
                      label="Status"
                      defaultValue={gameData?.Status || statusOptions[0].value} // Default to first option if no status is present
                      onChange={(e) => {
                        methods.setValue("Status", e.target.value);
                        if (e.target.value == "CANCELLED") {
                          setOpen(true);
                        }
                      }}
                      variant="outlined"
                      fullWidth
                      style={{ color: "rgba(81, 92, 99, 1)" }}
                    >
                      {statusOptions.map((status) => (
                        <MenuItem
                          sx={{
                            fontSize: "14px",
                            color: "rgba(81, 92, 99, 1)",
                          }}
                          key={status.value}
                          value={status.value}
                        >
                          {status.key}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  )}
                   
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                {methods.watch("Latitude") && methods.watch("Longitude") && (
                  <LoadScript googleMapsApiKey="AIzaSyB7eYRHUhX2ACrnGw6seFkQRlQ_mzel53Q">
                    <GoogleMap
                      mapContainerStyle={{ width: "100%", height: "140px" }}
                      center={{
                        lat: parseFloat(gameData?.Latitude) || 0,
                        lng: parseFloat(gameData?.Longitude) || 0,
                      }}
                      zoom={10}
                    >
                      <Marker
                        position={{
                          lat: parseFloat(gameData?.Latitude) || 0,
                          lng: parseFloat(gameData?.Longitude) || 0,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                )}
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={6}>
                    <RHFTextField
                      label="Featured Priority"
                      name="FeaturedPriority"
                      variant="outlined"
                      fullWidth
                      type="number"
                    />
                  </Grid> */}
            <div className="flex-container">
              <Button
                disabled={isDisable}
                variant="contained"
                className="submit-button"
                type="submit"
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)",
                  color: "#fff", // Ensure the text color is readable
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                  },
                  textTransform: "unset",
                }}
              >
                Update
              </Button>
            </div>
          </Box>
        </form>
      </FormProvider>
      {open && (
        <ConfirmDialog
          confirmText={"OK"}
          title={"Game Warning"}
          onCancel={() => setOpen(false)}
          onConfirm={onUpdateGameStatus}
          content={
            <p>
              Are you sure? Once cancelled,this game <br />
              cannot be restored.
            </p>
          }
        />
      )}
      {viewVideo ? (
        <VideoComponent
          methods={methods}
          handleClose={() => setViewVideo(false)}
        />
      ) : (
        ""
      )}
      {viewPlan ? (
        <ViewPlanPopup
          open={viewPlan}
          handleClose={() => setViewPlan(false)}
          id={methods.getValues("PlanID")}
        />
      ) : (
        ""
      )}
      {(gameData?.Status === "COMPLETED" || gameData?.Status === "RUNNING") &&
        leaderboardData.length > 0 && (
          <Stack>
            <LeaderboardTable leaderboardData={leaderboardData} />
          </Stack>
        )}
    </div>
  );
}
